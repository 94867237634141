import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from "swiper";

import PopupForm from "../components/popupForm";


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class VisaSupport extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	componentDidMount() {
		document.getElementById('speak-to-an-expert').onclick = function () {
			document.getElementById('book-an-appointment').click();
		}
	}
	render() {

		const post = this.props.data.allWordpressPage.nodes[0];
		const metatitle = post.acf.meta_title;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase;
		const canonical_url =`https://theworldgrad.com${this.props.path}`;

		var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
		if (post.featured_media) {
			headerBackground = post.featured_media.source_url;
		}
		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
						<title>{metatitle}</title>
						<meta name="description" content={metadescription} />
						{(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
						<link rel="canonical" href={canonical_url} /> 
						{(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
						
					</Helmet>



					<div class="newStyle font-poppins">
					<div className="container">
					<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
												<li class="breadcrumb-item">
													<a href="/">Home </a>
												</li>
												<li class="breadcrumb-item active"> {post.title}</li>
											</ol>
					</div>
						<section class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-100px-tb position-relative bg-cover480br extra-big-section" style={{ backgroundImage: "url(" + headerBackground + ")" }} >
							<div class="opacity-extra-medium bg-medium-slate-blue"></div>
							<div class="container">
								<div class="row">
									<div class="col-12 col-xl-10 col-lg-10 col-md-10 position-relative page-title-large ">
										<h1 class="text-white alt-font font-weight-500 letter-spacing-minus-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-10px-bottom">
											{(() => {
											if (post.acf.custom_h1 != '' && post.acf.custom_h1 != null) {
												return (post.acf.custom_h1)
											} else {
												return (post.title)

											}
										})()}</h1>
									</div>
								</div>
							</div>
						</section>
						<section class="half-section">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-one-bottom ">
										<div class="col-12 p-0  wow animate__slideInLeft" >
											<div class="w-90 lg-w-80 sm-w-100 text-justify " >
												<div class="no-margin-bottom program-content" dangerouslySetInnerHTML={{ __html: post.content }} />
											
												<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md center-button-110 modal-popup" id="speak-to-an-expert" href="#get-in-touch">Speak to an Expert</a>
											</div>

										</div>

									</div>


									<div class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6 margin15 mt-5 mt-lg-0  mx-0 p-0 position-relative wow" data-wow-delay="0.1s" style={{ backgroundImage: "url(" + post.acf.section_image.source_url + ")" }}  > 
										<img src={post.acf.section_image.source_url} alt={post.title} data-no-retina="" class="br-15 margin15 opacity-0 " />
										<div class="opacity-very-light bg-medium-slate-blue br-15 "></div>
									</div>
								</div>
							</div>
						</section>

					</div>

				</Layout>
			</div>
		);
	}
}
export default VisaSupport;
export const VisaSupportPage = graphql`
    {
        allWordpressPage(filter: {wordpress_id: {in: 7018}}) {
            nodes {
            	title
				content
				featured_media {
					source_url
				}
            	wordpress_id
            	acf {
					custom_h1
					section_image {
					source_url
				}
                meta_title
	meta_description
focus_keyphrase
canonical_url
dynamic_schema
               
          }
        }
    }   
}
`
